/* eslint-disable no-nested-ternary */
import { HighlightedArticle } from '../HighlightedArticle/HighlightedArticle.component';
import { JournalContext } from 'pages/index';
import { Content } from 'pleinchamp-api-client/dist/src/content/search';
import React, { ComponentProps, useCallback, useContext, useMemo } from 'react';
import { ADVERT_FORMAT_ID } from '@adverts/business/Adverts.contants';
import { insertAdvertInArray } from '@adverts/business/adverts.utils';
import { usePlcApiCall } from '@api/business/api.hook';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { ContentCardLoader } from '@components/content/ContentCard/ContentCardLoader.component';
import { PlcMessageWithRetry } from '@components/PlcMessage/PlcMessageWithRetry.component';
import { Text } from '@components/Text/Text.component';
import { HighlightedContentLoader } from '@content/HighlightedContent/HighlightedContent.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { SearchContentType } from '@search/business/search.types';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { NB_JOURNAL_NEWS_HEADLINES } from '@utils/api.utils';
import { buildContentCard } from '@utils/card';
import { plcDayjs } from '@utils/date';
import { fetchNewHeadlines } from '@utils/fetch';
import { getAllContentTypeLinkProps } from '@utils/url';
import classnames from 'classnames';
import { GeneralContext } from 'features/business/app.contexts';

import styles from './JournalNewsHeadlines.module.scss';

type Props = ComponentProps<'section'>;

function sortNewestFirst(a: Content, b: Content) {
  const dateA = plcDayjs(a.publishDate);
  const dateB = plcDayjs(b.publishDate);

  if (dateA.isBefore(dateB)) {
    return 1;
  }
  if (dateA.isAfter(dateB)) {
    return -1;
  }
  return 0;
}

export const JournalNewsHeadlines = ({ className, ...rest }: Props) => {
  const {
    newsHeadlines: { initialData },
  } = useContext(JournalContext);
  const { setSearchQuery } = useContext(GeneralContext);
  const resetSearchQuery = useCallback(() => setSearchQuery?.(''), [setSearchQuery]);
  const isMobile = useIsBreakpointDown(Breakpoint.s);

  const { data: newsHeadlines, isValidating, error, retryCallback } = usePlcApiCall(fetchNewHeadlines, [], {
    initialData,
  });

  const newsWithAd = useMemo(
    () =>
      insertAdvertInArray(
        (newsHeadlines || []).sort(sortNewestFirst).map(item => buildContentCard(item, isMobile)),
        3,
        NB_JOURNAL_NEWS_HEADLINES,
        ADVERT_FORMAT_ID.NativeAd
      ),
    [newsHeadlines, isMobile]
  );

  const rootClass = classnames(styles['journal-news-headlines'], className);
  const highlightedNews = newsHeadlines ? newsHeadlines[0] : undefined;
  const allHref = getAllContentTypeLinkProps(SearchContentType.Article);

  return (
    <section className={rootClass} {...rest}>
      <Text className="plc-mb-basis" i18nKey="journal.headlines.title" tag="h2" variant="h3" />
      {isValidating ? (
        <>
          <HighlightedContentLoader className="none flex-up-m plc-mb-basis" uniqueKey="highlighted-journal" />
          <div className="grid-m-3-s-2">
            <ContentCardLoader uniqueKey="1" />
            <ContentCardLoader uniqueKey="2" />
            <ContentCardLoader uniqueKey="3" />
            <ContentCardLoader uniqueKey="4" />
          </div>
        </>
      ) : error ? (
        <PlcMessageWithRetry retryCallback={retryCallback} />
      ) : (
        <>
          {highlightedNews && (
            <HighlightedArticle article={highlightedNews} className="none flex-up-m plc-mb-basis" isMobile={isMobile} />
          )}
          <div className="grid-m-3-s-2">{newsWithAd}</div>
          <PlcAnchor className="see-all flex block-up-s" linkProps={{ as: allHref, href: allHref }}>
            <PrimaryButton
              className="flex flex-1 block-up-s"
              onClick={resetSearchQuery}
              textProps={{ i18nKey: 'actu.last.see-all' }}
              wrapperClassName="flex-1"
            />
          </PlcAnchor>
        </>
      )}
    </section>
  );
};
