import React from 'react';
import { ChevronRightIcon } from '@components/icons/ChevronRight.icon';
import classnames from 'classnames';
import styles from './Arrows.module.scss';

const NextArrow = ({ className, style, onClick }: any) => (
  <button className={classnames(styles['slick-arrow'], styles['slick-arrow'], className)} onClick={onClick} style={style} type="button">
    <ChevronRightIcon height={15} width={10} />
  </button>
);

export { NextArrow };
