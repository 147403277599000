/* eslint-disable no-irregular-whitespace */
import { Content } from 'pleinchamp-api-client';
import React, { ComponentProps, FC } from 'react';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { ContentTagList } from '@components/content/ContentTagList/ContentTagList.component';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { Text } from '@components/Text/Text.component';
import { EventDate } from '@events/components/EventDate/EventDate.component';
import { EventLocation } from '@events/components/EventLocation/EventLocation.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Spacing } from '@styles/Spacing.style';
import { buildTagList } from '@utils/card';
import { getAs, urlEnum } from '@utils/url';
import classnames from 'classnames';

import styles from './JournalAgendaLine.module.scss';

interface Props extends ComponentProps<'li'> {
  event: Content;
}

function JournalAgendaLine({ event, className, ...rest }: Props) {
  if (!event.eventDetail) {
    return null;
  }
  const { startDate, endDate } = event.eventDetail;
  const href = `${urlEnum.event}/[slug]`;
  const as = getAs(href, { slug: event.slug }, false);
  const tags = buildTagList(event.activityCodes, event.interestCodes);
  const rootClass = classnames(styles['journal-agenda-item'], 'plc-mb-basis', className);

  return (
    <li className={rootClass} {...rest}>
      <div className="flex column row-up-m align-start plc-mb-m">
        <EventDate
          className="white-space-nowrap-up-m plc-mb-s plc-mr-up-m-m"
          compact
          endDate={new Date(endDate)}
          startDate={new Date(startDate)}
          textProps={{ variant: 'h3' }}
        />
        <PlcAnchor className="flex-1" linkProps={{ as, href }}>
          <Text className="cursor-pointer plc-mb-s plc-mr-up-m-m" tag="p" variant="big">
            {event.title}
          </Text>
        </PlcAnchor>
        <EventLocation address={event.eventDetail.address} />
      </div>
      <footer className="flex column row-up-m space-between-up-m">
        {tags.length > 0 && <ContentTagList asLink className="plc-mb-m plc-mb-up-m-basis" oneLine tags={tags} />}
        <BasicLink
          as={as}
          className="plc-mb-m plc-mb-up-m-basis"
          href={href}
          role="navigation"
          textProps={{ i18nKey: 'journal.agenda.go-to', withArrow: true }}
        />
      </footer>
    </li>
  );
}

const JournalAgendaLineLoader: FC<{ uniqueKey: string }> = ({ uniqueKey }) => {
  return (
    <li className="journal-agenda-item">
      <PlcTextListLoader
        textProps={[
          { bottomSpacing: Spacing.m, variant: 'h3', width: 100 },
          { bottomSpacing: Spacing.basis, numberOfLine: 2, variant: 'p', width: 200 },
        ]}
        uniqueKey={uniqueKey}
        width={200}
      />
    </li>
  );
}

export { JournalAgendaLine, JournalAgendaLineLoader };
