import { LastAnalysisComponent } from './LastAnalysisComponent.component';
import { JournalContext } from 'pages/index';
import { useContext } from 'react';
import { usePlcApiCall } from '@api/business/api.hook';
import { fetchLastAnalysis } from '@api/business/api.utils';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';

import styles from './LastAnalysisComponent.module.scss';

type Props = { parentDivClass?: string };

function LastAnalysis({ parentDivClass }: Props) {
  const {
    analyse: { initialData: initialAnalyse },
  } = useContext(JournalContext);

  const analyse = usePlcApiCall(fetchLastAnalysis, [], {
    initialData: initialAnalyse,
  });
  const isMobile = useIsBreakpointDown(Breakpoint.m);

  return (
    <div className={parentDivClass}>
      <LastAnalysisComponent
        className={`${styles['analyse-flex-child-court']} plc-mb-basis`}
        data={analyse.data}
        i18nTitle="common.last-analysis"
        isMobile={isMobile}
        isValidating={analyse.isValidating}
      />
    </div>
  );
}

export default LastAnalysis;
