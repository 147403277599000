import { JournalContext } from 'pages/index';
import React, { useContext } from 'react';
import { usePlcApiCall } from '@api/business/api.hook';
import { FreeFieldComponent } from '@components/FreeFieldComponent/FreeFieldComponent.component';
import { PlcMessageWithRetry } from '@components/PlcMessage/PlcMessageWithRetry.component';
import { fetchFreeField } from '@utils/fetch';

export function JournalFreeField() {
  const {
    freeFieldContent: { initialData },
  } = useContext(JournalContext);

  const freeFieldContent = usePlcApiCall(fetchFreeField, [], {
    initialData,
  });

  if (freeFieldContent.error) {
    return <PlcMessageWithRetry retryCallback={freeFieldContent.retryCallback} />;
  }

  if (!freeFieldContent.data) {
    return null;
  }

  return (
    <FreeFieldComponent
      className="plc-mb-l"
      content={freeFieldContent.data}
      isLoading={freeFieldContent.isValidating}
    />
  );
}
