import { FreeFieldCode } from 'pleinchamp-api-client';
import { ContentApi } from '@api/business/api.utils';

function fetchForCode(code: FreeFieldCode) {
  return ContentApi.getFreeField(code)
    .then(res => res.data)
    .catch(err => {
      console.error(err);
      return undefined;
    });
}

export const FreeFieldApi = {
  fetchForCode,
};
