import React from 'react';
import { SvgProps } from './icon.types';

function ChevronLeftIcon({ title, fillColor = '#2D3049', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 10 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title ? <title>{title}</title> : null}
      <path d="M9.2 12.8l-1.4 1.4-7-7 7-7 1.4 1.42-5.58 5.59z" fill={fillColor} fillRule="nonzero" />
    </svg>
  );
}

export { ChevronLeftIcon };
