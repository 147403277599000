import { FreeField, Media } from 'pleinchamp-api-client';
import React, { ComponentPropsWithRef, FC, useEffect, useState } from 'react';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { PlcImg } from '@components/PlcImg/PlcImg.component';
import { Text } from '@components/Text/Text.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { Spacing } from '@styles/Spacing.style';
import classnames from 'classnames';
import styles from './FreeFieldComponent.module.scss';

export interface FreeFieldComponentProps extends Omit<ComponentPropsWithRef<'section'>, 'content'> {
  content: Omit<FreeField, 'freeFieldCode'> & { freeFieldCode?: FreeField['freeFieldCode'] };
  reverse?: boolean;
  isLoading?: boolean;
}

const FreeFieldComponent: FC<FreeFieldComponentProps> = ({
  className,
  content,
  reverse,
  style = {},
  isLoading = false,
  ...rest
}) => {
  const isMobile = useIsBreakpointDown(Breakpoint.s);
  const rootClass = classnames(styles['free-field'],'flex wrap space-between-up-m', className);
  const { title, image, imageMobile, body, link } = content;
  const [imageState, setImage] = useState<Media>(isMobile && imageMobile ? imageMobile : image);

  useEffect(() => {
    setImage(isMobile && imageMobile ? imageMobile : image);
  }, [isMobile]);

  const ImgLoader = <PlcImgLoader height={isMobile ? 327 : 497} uniqueKey="freeField-img" width={isMobile ? 327 : 497 + 2 * Spacing.m} />;

  return (
    <section className={rootClass} {...rest} style={{ ...style, flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <figure className="plc-mb-m plc-mb-up-m-0">
        {isLoading ? (
          ImgLoader
        ) : (
          <PlcImg alt={imageState.alt} loader={ImgLoader} plcSrc={{ src: imageState.url, type: 'external' }} />
        )}
      </figure>
      <div className={styles["content"]}>
        <div className="wrapper">
          {isLoading ? (
            <PlcTextListLoader
              textProps={[
                { bottomSpacing: Spacing.s, variant: isMobile ? 'p-style-h3' : 'p-style-h2', width: 50 },
                { bottomSpacing: Spacing.m, numberOfLine: 3, variant: 'small', width: 100 },
                { variant: 'p', width: 30 },
              ]}
              uniqueKey="freeField-textList"
            />
          ) : (
            <>
              <Text tag="p" variant={isMobile ? 'p-style-h3' : 'p-style-h2'}>
                {title}
              </Text>
              <Text tag="p" variant="small">
                {body}
              </Text>
              {link && (
                <BasicLink href={link.url} textProps={{ withArrow: true }}>
                  {link.name}
                </BasicLink>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export { FreeFieldComponent };
