/* eslint-disable no-nested-ternary */
import isEmpty from 'lodash/isEmpty';
import { JournalContext } from 'pages/index';
import { Content } from 'pleinchamp-api-client/dist/src/content/search';
import React, { ComponentPropsWithRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { usePlcApiCall } from '@api/business/api.hook';
import { FlatButtonVariant } from '@components/buttons/Buttons.type';
import { PlcMessageWithRetry } from '@components/PlcMessage/PlcMessageWithRetry.component';
import { Text } from '@components/Text/Text.component';
import {
  JournalAgendaLine,
  JournalAgendaLineLoader,
} from '@journal/components/JournalAgenda/JournalAgendaLine/JournalAgendaLine.component';
import { PlcButtonGroup } from '@layout/components/PlcButtonGroup/PlcButtonGroup.component';
import { SearchContentType } from '@search/business/search.types';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { userSelectors } from '@user/business/User.store';
import { fetchEvents } from '@utils/fetch';
import { getAllContentTypeLinkProps } from '@utils/url';
import classnames from 'classnames';

type Props = ComponentPropsWithRef<'section'>;

function JournalAgenda({ className, ...rest }: Props) {
  const isSmartphone = useIsBreakpointDown(Breakpoint.s);
  const {
    events: { initialData },
  } = useContext(JournalContext);

  const user = useSelector(userSelectors.getUser);
  const stateOrProvinceCode = user?.address?.stateOrProvinceCode;
  const { data: events, isValidating, error, retryCallback } = usePlcApiCall(fetchEvents, [stateOrProvinceCode], {
    deduping: false,
    initialData,
  });

  const displayedEvents: Content[] = events ? events.slice(0, isSmartphone ? 2 : 3) : [];

  const rootClassnames = classnames('agenda', className);
  return (
    <section className={rootClassnames} {...rest}>
      <Text className="plc-mb-basis" i18nKey="search.filter.event" tag="h2" variant="h3" />
      <ul>
        {isValidating ? (
          <>
            <JournalAgendaLineLoader uniqueKey="JournalAgendaLine1" />
            <JournalAgendaLineLoader uniqueKey="JournalAgendaLine2" />
            <JournalAgendaLineLoader uniqueKey="JournalAgendaLine3" />
          </>
        ) : error ? (
          <PlcMessageWithRetry retryCallback={retryCallback} />
        ) : (
          <>
            {displayedEvents.map((event: Content, index: number) => (
              <JournalAgendaLine
                key={event.id}
                className={index < displayedEvents.length - 1 ? 'border-bottom' : undefined}
                event={event}
              />
            ))}
            {isEmpty(displayedEvents) && (
              <Text className="plc-mb-basis" flavour="grey" i18nKey="journal.agenda.no-event" variant="p" />
            )}
          </>
        )}
      </ul>
      <PlcButtonGroup
        buttons={[
          {
            className: 'flex flex-1',
            isSmall: false,
            linkProps: { href: getAllContentTypeLinkProps(SearchContentType.Event) },
            textI18nKey: 'journal.go-to.all-events',
            variant: FlatButtonVariant.primary,
            wrapperClassName: 'flex flex-1',
          },
        ]}
        className="plc-mb-l"
      />
    </section>
  );
}

export { JournalAgenda };
