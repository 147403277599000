import React from 'react';
import { ChevronLeftIcon } from '@components/icons/ChevronLeft.icon';
import classnames from 'classnames';
import styles from './Arrows.module.scss';

const PreviousArrow = ({ className, style, onClick }: any) => (
  <button
    className={classnames(styles['slick-arrow'], styles['slick-prev'], className)}
    onClick={onClick}
    style={style}
    type="button"
  >
    <ChevronLeftIcon height={15} width={10} />
  </button>
);

export { PreviousArrow };
