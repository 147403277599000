import Head from 'next/head';
import { FreeField } from 'pleinchamp-api-client/dist/src/content/content';
import { Content } from 'pleinchamp-api-client/dist/src/content/search';
import { Analysis } from 'pleinchamp-api-client/dist/src/market/market';
import React, { createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLastAnalysis, fetchMostViewedNews } from '@api/business/api.utils';
import { MostViewedContent } from '@components/content/MostViewedContent/MostViewedContent.component';
import { JournalLayout } from '@journal/components/JournalLayout/JournalLayout.component';
import { PlcDispatch } from '@store/store';
import { formatPropsForStatic, NB_JOURNAL_MOST_VIEWED_NEWS } from '@utils/api.utils';
import { fetchEvents, fetchFreeField, fetchNewHeadlines } from '@utils/fetch';
import { defaultNamespaces } from '@utils/strings';
import { PlcNextPage, PlcNextPageContext, PlcPageContextProps } from 'features/business/app.types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

interface Props {
  events: Content[];
  newsHeadlines: Content[];
  customNews: Content[];
  freeFieldContent: FreeField | undefined;
  mostViewedNews: MostViewedContent[];
  analyse: Analysis[];
}

const NAMESPACES = [...defaultNamespaces, 'journal'];

export const JournalContext = createContext<PlcPageContextProps<Props>>({
  analyse: { isValidating: false },
  customNews: { isValidating: false },
  events: { isValidating: false },
  freeFieldContent: { isValidating: false },
  mostViewedNews: { isValidating: false },
  newsHeadlines: { isValidating: false },
});

const IndexPage: PlcNextPage<Partial<Props>> = (props) => {
  const {
    settings: { fetchUserProductTypes },
  } = useDispatch<PlcDispatch>();

  const { analyse, newsHeadlines, customNews, events, freeFieldContent, mostViewedNews } = props;

  useEffect(() => {
    fetchUserProductTypes().then();
  }, []);

  return (
    <JournalContext.Provider
      value={{
        analyse: { initialData: analyse, isValidating: false },
        customNews: { initialData: customNews, isValidating: false },
        events: { initialData: events, isValidating: false },
        freeFieldContent: { initialData: freeFieldContent, isValidating: false },
        mostViewedNews: { initialData: mostViewedNews, isValidating: false },
        newsHeadlines: { initialData: newsHeadlines, isValidating: false },
      }}
    >
      <Head>
        <meta content="7200" httpEquiv="refresh" />
      </Head>
      <JournalLayout />
    </JournalContext.Provider>
  );
};

export async function getStaticProps({ locale }: PlcNextPageContext) {
  const props = await Promise.all([
    fetchEvents(),
    fetchNewHeadlines(),
    fetchFreeField(),
    fetchMostViewedNews(NB_JOURNAL_MOST_VIEWED_NEWS),
    fetchLastAnalysis(),
    serverSideTranslations(locale || 'fr', NAMESPACES),
  ])
    .then(([events, newsHeadlines, freeFieldContent, mostViewedNews, analyse, i18n]) => ({
      analyse,
      events,
      freeFieldContent,
      mostViewedNews,
      newsHeadlines,
      ...i18n,
    }))
    .catch((err) => {
      console.error('Error occured on /index.tsx', err);
      return serverSideTranslations(locale || 'fr', NAMESPACES);
    });

  return formatPropsForStatic(props);
}

export default IndexPage;
