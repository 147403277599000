import React from 'react';
import { SvgProps } from './icon.types';

function ChevronRightIcon({ title, fillColor = '#2D3049', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 10 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title ? <title>{title}</title> : null}
      <path d="M0 1.62L1.41.21l7 7-7 7L0 12.79l5.59-5.58z" fill={fillColor} fillRule="nonzero" />
    </svg>
  );
}

export { ChevronRightIcon };
